.stuns-solar {
    font-family: Arial;

    .parameter-box-container {
        display: flex;
        align-items: center;
        justify-content: center;
    }
    
    .parameter-box {
        padding: 16px;

        .icon {
            display: block;
            width: 50px;
            height: 50px;
            margin: 0 auto 16px auto;
        }
        
        .value {
            display: block;
            font-size: 22px;
            font-weight: bold;
            text-align: center;
        }
    }
}
